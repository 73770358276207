import React from "react";
import {Button} from 'antd';

export default class Menu extends React.Component {

  menuButtons = [];

  constructor(props) {
    super(props);
    this.menuButtons = this.props.menuButtons;
  }

  click(e) {
    this.props.contentTo(e)
  };

  getButtons(content) {
    return this.menuButtons.map(e => {
      return <Button onClick={() => {
        this.click(e.content)
      }} type={content === e.content ? "primary" : null} block style={buttonStyle}>{e.title}</Button>
    });
  }

  render() {
    const content = this.props.content;
    return (
      <>
        {this.getButtons(content)}
      </>
    )
  }
}

const buttonStyle = {
  height: 36,
  paddingLeft: 8,
  paddingTop: 8,
  paddingBottom: 8,
  marginBottom: 8,
  border: "none",
  textAlign: "left"
};
