import { Tabs, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import Sponsor from '../../../common/sponsor/Sponsor'
import { contractTypes } from '../../../utils/constants/contractTypes'

const { TabPane } = Tabs

const SponsorCont = ({ type }) => {
  const sponsors = useSelector((state) => state.sponsorCont.sponsorList)

  // is specific
  const isSpecific = () => {
    if (type === contractTypes.SPECIFIC) return true
    return false
  }

  return (
    <Tabs defaultActiveKey="new" className="sponsor_cont">
      {!isSpecific() && (
        <TabPane tab={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>} disabled />
      )}
      <TabPane
        tab={
          <Typography.Title level={5} className="mb0">
            Kafil ma'lumotlari
          </Typography.Title>
        }
        disabled
      />
      {sponsors?.map((sponsor, index) => (
        <TabPane tab={`Kafil ${index + 1}`} key={index + 1}>
          <Sponsor data={sponsor} index={index} type={type} />
        </TabPane>
      ))}
      <TabPane tab="+ Qo'shish" key="new">
        <Sponsor type={type} />
      </TabPane>
    </Tabs>
  )
}

export default SponsorCont
