import { LoadingOutlined } from '@ant-design/icons'
import { Popover, Spin, Table, Tag, Typography } from 'antd'
import React, { useState } from 'react'
import { RiEdit2Line } from 'react-icons/all'
import NumberFormat from 'react-number-format'
import { Link, useHistory } from 'react-router-dom'
import { useQueryString } from '../../hooks/useQueryString'
import { useDeletePaymeCard, useDeleteUzCard } from '../../queries/mutations'
import { AddCardModal } from './AddCardModal'
import UpdateClientModal from './UpdateClientModal'
import { cardTypes } from '../../utils/constants/cardTypes'

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 12, marginLeft: '10px', color: '#5f5e5e' }}
    spin
  />
)
const { Column } = Table

function ContractTable({ AllData, spinner, isFetching }) {
  const deletePaymeCard = useDeletePaymeCard()
  const deleteUzCard = useDeleteUzCard()
  const { push } = useQueryString()
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const [cardType, setCardType] = useState(null)
  const [visiblePopover, setVisiblePopover] = useState({
    open: false,
    id: null,
  })
  const [clientUpdateModal, setClientUpdateModal] = useState({
    visible: false,
    contractType: null,
    clientId: null,
  })

  // open client update modal
  const openClientUpdateModal = (e, clientId, contractType) => {
    e.stopPropagation()
    setClientUpdateModal({
      visible: true,
      clientId,
      contractType,
    })
    setVisiblePopover({ ...visiblePopover, open: false })
  }

  // close client update modal
  const closeClientUpdateModal = () => {
    setClientUpdateModal({
      visible: false,
      contractType: null,
      clientId: null,
    })
  }

  // open add card modal
  const openAddCardModal = async (e, record, cardType) => {
    e.stopPropagation()
    setCardType(cardType)

    if (cardType === cardTypes.PAYME) {
      if (record.hasCard) {
        await deletePaymeCard.mutateAsync(record.id)
      } else {
        setIsOpen(true)
        push('contractId', record.id)
      }
    } else {
      if (record.hasUzCard) {
        await deleteUzCard.mutateAsync(record.id)
      } else {
        setIsOpen(true)
        push('contractId', record.id)
      }
    }

    setVisiblePopover({
      open: false,
      id: null,
    })
  }

  // popover content
  const content = (record) => {
    return (
      <div style={{ width: '180px' }}>
        <div>
          <Link
            to={'/main/contract/' + record.dataKey}
            className={'monthlyPaymentsChange'}
          >
            To'liq shartnoma
          </Link>
        </div>
        <div>
          <p
            onClick={(e) =>
              openClientUpdateModal(e, record.clientId, record.type)
            }
            className="monthlyPaymentsChange m0"
          >
            Tahrirlash
          </p>
        </div>
        <div onClick={(e) => openAddCardModal(e, record, cardTypes.PAYME)}>
          <Typography.Text
            className={'monthlyPaymentsChange'}
            type={record.hasCard ? 'danger' : 'success'}
          >
            {record.hasCard
              ? "Kartani o'chirish (payme)"
              : "Karta qo'shish (payme)"}
            {deletePaymeCard.isLoading && antIcon}
          </Typography.Text>
        </div>
        <div onClick={(e) => openAddCardModal(e, record, cardTypes.UZCARD)}>
          <Typography.Text
            className={'monthlyPaymentsChange'}
            type={record.hasUzCard ? 'danger' : 'success'}
          >
            {record.hasUzCard
              ? "Kartani o'chirish (uzcard)"
              : "Karta qo'shish (uzcard)"}
            {deletePaymeCard.isLoading && antIcon}
          </Typography.Text>
        </div>
      </div>
    )
  }

  return (
    <>
      <Table
        showSizeChanger={true}
        size={'middle'}
        loading={spinner}
        rowKey={'id'}
        dataSource={AllData}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => {
              history.push('/main/contract/' + record.id)
            },
          }
        }}
        rowClassName={(record) => {
          return `AktivStatus${record.payedPercent}`
        }}
      >
        <Column title="Raqami" dataIndex="number" key="number" />
        <Column
          title={() => (
            <span
              style={{ display: 'flex', height: '1rem', alignItems: 'center' }}
            >
              Sana
              {isFetching && <Spin indicator={antIcon} />}
            </span>
          )}
          dataIndex="date"
          key="date"
        />
        <Column title="Mijoz" dataIndex="clientName" key="clientName" />
        <Column
          title="Telefon raqam"
          dataIndex="clientPhone"
          key="clientPhone"
        />
        <Column
          width={'10rem'}
          title="Summa"
          // dataIndex="amount"
          key="amount"
          render={(record) => {
            return (
              <>
                <NumberFormat
                  value={record?.amount}
                  displayType={'text'}
                  thousandSeparator={' '}
                />
                &nbsp;
                {record.contractAmountType}
              </>
            )
          }}
        />
        <Column
          width="5rem"
          title="Turi"
          dataIndex="type"
          key="type"
          render={(type) => (
            <Tag
              color={
                (type === 'SIMPLE' && '#2ecb38') ||
                (type === 'SPECIFIC' && '#1890ff')
              }
            >
              {(type === 'SIMPLE' && 'Oddiy') ||
                (type === 'SPECIFIC' && 'Maxsus')}
            </Tag>
          )}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(status) => (
            <Tag
              style={{
                width: '90px',
                textAlign: 'center',
              }}
              color={
                (status === 'ACTIVE' && '#7c30d9') ||
                (status === 'STARTED' && '#2ecb38') ||
                (status === 'FINISHED' && '#700303') ||
                (status === 'CANCELLED' && '#ff7825') ||
                'lime'
              }
              key={status}
            >
              {(status === 'ACTIVE' && 'Faol') ||
                (status === 'STARTED' && "Boshlang'ich") ||
                (status === 'FINISHED' && 'Tugallangan') ||
                (status === 'CANCELLED' && 'Bekor qilingan') ||
                status}
            </Tag>
          )}
        />
        <Column title="Masul" dataIndex="createdBy" key="createdBy" />
        <Column
          render={(record) => {
            return (
              <Popover
                visible={visiblePopover.open && visiblePopover.id === record.id}
                placement="leftTop"
                onVisibleChange={(visible) => {
                  setVisiblePopover({ open: visible, id: record.id })
                }}
                trigger="click"
                content={() => content(record)}
              >
                <span
                  onClick={(e) => {
                    e.stopPropagation()
                    setVisiblePopover({
                      open: true,
                      id: record.id,
                    })
                  }}
                  className="ClientTableIcon"
                >
                  <RiEdit2Line />
                </span>
              </Popover>
            )
          }}
        />
      </Table>
      <AddCardModal isOpen={isOpen} setIsOpen={setIsOpen} cardType={cardType} />
      <UpdateClientModal
        visible={clientUpdateModal.visible}
        contractType={clientUpdateModal.contractType}
        clientId={clientUpdateModal.clientId}
        closeModal={closeClientUpdateModal}
      />
    </>
  )
}

export default ContractTable
