import { PRODUCT_LIST, SET_PRODUCT_LIST } from './types'

const initialState = {
  productList: [],
}

const productReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case PRODUCT_LIST:
      return {
        ...state,
        productList: payload,
      }
    case SET_PRODUCT_LIST:
      return {
        ...state,
        productList: payload,
      }
    default:
      return state
  }
}

export default productReducer
