import React, { useState, useEffect } from 'react'
import { Button, Col, Input, Layout, Row, Spin, Typography } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import { CgCheck } from 'react-icons/cg'
import { useQueryClient } from 'react-query'
import PayModal from './PayModal'
import {
  useOneContract,
  usePaymentPlusTables,
  useSearchContract,
} from '../../queries/queries'
import { queryNames } from '../../queries/queryNames'
import TableNextPayment from './TableNextPayment'
import HistoryPayment from './HistoryPayment'
import { useSelector, useDispatch } from 'react-redux'
import { contractSave } from '../../redux/actions'

const { Content } = Layout

const Plus = () => {
  const [clickCard, setClickCard] = useState(null)
  const [clickedId, setClickedId] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [searchString, setSearchString] = useState('')

  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const contracts = useSearchContract(searchString)
  const oneContract = useOneContract(clickedId)
  const tablesData = usePaymentPlusTables(clickedId)

  const savedContract = useSelector((state) => state.contractCont.savedContract)

  useEffect(() => {
    if (savedContract) {
      const clientPhone = savedContract?.clientDto?.clientPhones.find(
        (phone) => phone.active
      )
      setClickedId(savedContract?.id)
      setClickCard({
        id: savedContract?.id,
        number: savedContract?.number,
        date: savedContract?.createdDate,
        clientName: savedContract?.clientDto?.fullName,
        clientPhone: clientPhone?.phone,
        amount: savedContract?.amount,
        status: savedContract?.status,
      })
    }
    return () => {
      dispatch(contractSave(null))
    }
  }, [dispatch, savedContract])

  const getContractFunc = (contract) => {
    setClickedId(contract.id)
    setClickCard(contract)
  }

  const closeModalFunc = () => {
    queryClient.invalidateQueries(queryNames.CONTRACTS, clickedId)
    queryClient.invalidateQueries(queryNames.PAYMENT_PLUS_TABLES, clickedId)
    setOpenModal(false)
  }
  const timerRef = React.useRef(null)
  return (
    <div>
      <Content
        style={{
          padding: '10px 20px 0 20px',
          overflow: 'initial',
          backgroundColor: '#FAFBFD',
        }}
      >
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Input
              className="circle-input"
              style={{ width: 400, height: '38px' }}
              placeholder="Tezkor qidiruv..."
              prefix={<SearchOutlined className="site-form-item-icon" />}
              // onPressEnter={(e) => {
              //   smartSearchFunc (e.target.value)
              // }}
              onChange={(e) => {
                clearTimeout(timerRef.current)
                timerRef.current = setTimeout(() => {
                  setSearchString(
                    e.target.value.length > 2 ? e.target.value : ''
                  )
                }, 1000)
              }}
            />
            {contracts.isLoading ? (
              <Spin
                style={{
                  display: 'block',
                  textAlign: 'center',
                  marginTop: '40px',
                }}
              />
            ) : (
              <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                {!clickCard &&
                  contracts?.data?.map((contract) => {
                    return (
                      <Button
                        type="text"
                        className="YuridikCard"
                        style={{ width: '98%', padding: '8px' }}
                        onClick={() => {
                          getContractFunc(contract)
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            padding: '0 10px',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography level={5} style={{ marginRight: '5px' }}>
                            <span style={{ marginRight: '5px' }}>
                              Shartnoma:
                            </span>
                            {contract.number}
                          </Typography>
                          <Typography level={5} style={{ marginRight: '5px' }}>
                            Summa:
                            <NumberFormat
                              style={{ margin: '0 5px' }}
                              value={contract.amount}
                              displayType={'text'}
                              thousandSeparator={' '}
                            />
                            {contract.contractAmountType}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            padding: '0 10px',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography level={5} style={{ marginRight: '5px' }}>
                            <span style={{ marginRight: '5px' }}>Tel:</span>
                            {contract.clientPhone}
                          </Typography>
                          <Typography level={5} style={{ marginRight: '5px' }}>
                            <span style={{ marginRight: '5px' }}>Mijoz:</span>
                            {contract.clientName}
                          </Typography>
                        </div>
                      </Button>
                    )
                  })}
              </div>
            )}
            {clickCard && (
              <>
                <Button
                  type="text"
                  className="YuridikCard"
                  style={{
                    backgroundColor: '#FFE500',
                    width: '97%',
                    padding: '8px',
                  }}
                  onClick={() => {
                    setClickedId(0)
                    setClickCard(null)
                  }}
                >
                  <CgCheck className="YuridikPersonClick" />
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        padding: '0 10px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography level={5} style={{ marginRight: '5px' }}>
                        <span style={{ marginRight: '5px' }}>Shartnoma:</span>
                        {clickCard.number}
                      </Typography>
                      <Typography level={5} style={{ marginRight: '5px' }}>
                        Summa:
                        <NumberFormat
                          style={{ margin: '0 5px' }}
                          value={clickCard.amount}
                          displayType={'text'}
                          thousandSeparator={' '}
                        />
                        {clickCard.contractAmountType}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        padding: '0 10px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography level={5} style={{ marginRight: '5px' }}>
                        <span style={{ marginRight: '5px' }}>Tel:</span>
                        {clickCard.clientPhone}
                      </Typography>
                      <Typography level={5} style={{ marginRight: '5px' }}>
                        <span style={{ marginRight: '5px' }}>Mijoz:</span>
                        {clickCard.clientName}
                      </Typography>
                    </div>
                  </div>
                </Button>
              </>
            )}
          </Col>
          <Col span={12}>
            <Typography className="ClientAddHeader">Shartnoma</Typography>
            {oneContract.isLoading ? (
              <Spin
                style={{
                  display: 'block',
                  textAlign: 'center',
                  marginTop: '40px',
                }}
              />
            ) : (
              clickedId !== 0 && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      fontSize: '14px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      className="ClientAddHeader"
                      style={{ marginRight: '5px', fontSize: '18px' }}
                    >
                      Umumiy summa:
                      <NumberFormat
                        style={{ marginLeft: '5px' }}
                        value={oneContract?.data?.amount}
                        displayType={'text'}
                        thousandSeparator={' '}
                      />
                    </Typography>
                    <Typography
                      className="ClientAddHeader"
                      style={{ marginRight: '5px', fontSize: '18px' }}
                    >
                      Birinchi to'lov:
                      <NumberFormat
                        style={{ marginLeft: '5px' }}
                        value={oneContract?.data?.firstPayment}
                        displayType={'text'}
                        thousandSeparator={' '}
                      />
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      fontSize: '14px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      className="ClientAddHeader"
                      style={{ marginRight: '5px', fontSize: '18px' }}
                    >
                      Qoldiq:
                      <NumberFormat
                        style={{ marginLeft: '5px' }}
                        value={oneContract?.data?.residue}
                        displayType={'text'}
                        thousandSeparator={' '}
                      />
                    </Typography>
                    <Typography
                      className="ClientAddHeader"
                      style={{ marginRight: '5px', fontSize: '18px' }}
                    >
                      To'langan:
                      <NumberFormat
                        style={{ marginLeft: '5px' }}
                        value={oneContract?.data?.payed}
                        displayType={'text'}
                        thousandSeparator={' '}
                      />
                    </Typography>
                  </div>
                </>
              )
            )}
          </Col>
        </Row>
        <div style={{ textAlign: 'center' }}>
          <Button
            type={'primary'}
            onClick={() => {
              setOpenModal(true)
            }}
            disabled={!clickCard}
          >
            To'lov qilish
          </Button>
        </div>
        {clickedId !== 0 && (
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <TableNextPayment
                isFetching={tablesData?.isFetching}
                loading={tablesData.isLoading}
                data={tablesData?.data?.tableMonthlyPayments}
              />
            </Col>
            <Col span={12}>
              <HistoryPayment
                isFetching={tablesData?.isFetching}
                loading={tablesData.isLoading}
                data={tablesData?.data?.historyPayments}
              />
            </Col>
          </Row>
        )}
      </Content>
      <PayModal
        modalOpen={openModal}
        closeModal={closeModalFunc}
        contractId={clickedId}
      />
    </div>
  )
}

export default Plus
