import { Col, Row, Select } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import SpecialProduct from '../SpecialContract/SpecialProduct'
import './product.scss'

const { Option } = Select
export const ProductCont = ({ currency, setCurrency }) => {
  // const [save, setSave] = useState(0)

  // handle currency
  const handleCurrency = (currency) => {
    setCurrency(currency)
  }

  const products = useSelector((state) => state.productCont.productList)
  return (
    <div className={'productCont'}>
      <Row className="ai-c header">
        <Col span={5} className="title">
          Shartnoma valyuta turi:
        </Col>
        <Col>
          <Select onChange={handleCurrency} value={currency}>
            <Option value="UZS">UZS</Option>
            <Option value="USD">USD</Option>
          </Select>
        </Col>
      </Row>
      <Row className={'header'}>
        <Col className="title" span={7}>
          Nomi
        </Col>
        <Col className="title" span={4}>
          Miqdori
        </Col>
        <Col className="title" span={5}>
          Narxi
        </Col>
        <Col className="title" span={5}>
          Umumiy summa
        </Col>
        <Col className="title" span={3} />
      </Row>
      {products?.map((data, index) => (
        // <Product data={data} key={index} index={index} currency={currency} />
        <SpecialProduct
          data={data}
          key={index}
          index={index}
          currency={currency}
        />
      ))}
      {/* <Product data={null} index={'new'} save={save} /> */}
      {/* <AutoCompleteProduct /> */}
      {/* <div onClick={() => setSave(save + 1)} className={'buttonAdd'}>
        <FiPlus />
        <span>Yangi mahsulot qo’shish</span>
      </div> */}
    </div>
  )
}

export default ProductCont
