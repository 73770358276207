import React, { useState } from 'react'
import {
  Col,
  DatePicker,
  Drawer,
  Input,
  Layout,
  Pagination,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { VscListFilter } from 'react-icons/vsc'
import ContractTable from './ContractTable'
import './styles.scss'
import NumberFormat from 'react-number-format'
import { useContracts } from '../../queries/queries'

const { Content } = Layout
const { RangePicker } = DatePicker
const initialFilter = {
  start: null,
  finish: null,
  statuses: null,
  types: null,
  name: null,
}

function Contract() {
  const [filter, setFilter] = useState(false)
  const [page, setPage] = useState(1)
  const [drableOpen, setDrableOpen] = useState(false)
  const [searchFields, setSearchFields] = useState(initialFilter)
  const [archieve, setArchieve] = useState(false)
  const contracts = useContracts({ searchFields, page, archieve })

  const statusOptions = () => {
    if (archieve) {
      return [
        { value: 'CANCELLED', label: 'Bekor qilingan', color: 'lime' },
        { value: 'FINISHED', label: 'Yakunlangan', color: '#ff7825' },
        { value: 'BLANK', label: "Bo'sh", color: '#ff7825' },
      ]
    } else {
      return [
        { value: 'ACTIVE', label: 'Faol', color: 'red' },
        { value: 'STARTED', label: "Boshlang'ich", color: 'green' },
      ]
    }
  }

  const timerRef = React.useRef(null)

  return (
    <div>
      <Content
        style={{
          padding: '10px 20px 0 20px',
          overflow: 'initial',
          backgroundColor: '#FAFBFD',
        }}
      >
        <Space
          direction="horizontal"
          style={{ width: '100%', margin: '20px 20px', marginRight: '0' }}
        >
          <Input
            className="circle-input"
            style={{ width: 400, height: '38px' }}
            placeholder="Tezkor qidiruv..."
            prefix={<SearchOutlined className="site-form-item-icon" />}
            suffix={
              <Tooltip title="Qo`llanma">
                <InfoCircleOutlined
                  onClick={() => {
                    setDrableOpen(true)
                  }}
                  style={{ color: 'rgba(0,0,0,.45)' }}
                />
              </Tooltip>
            }
            onChange={(e) => {
              clearTimeout(timerRef.current)
              timerRef.current = setTimeout(() => {
                setSearchFields({
                  ...searchFields,
                  name: e.target.value.length > 2 ? e.target.value : null,
                })
              }, 1000)
            }}
          />
          <button
            type="button"
            onClick={() => {
              setFilter((prev) => !prev)
            }}
            className="ClientFiltrButton"
          >
            <VscListFilter
              style={{
                position: 'absolute',
                fontSize: '1.4rem',
                left: '20px',
                fontFamily: 'IBM Plex Sans',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '18px',
                letterSpacing: '0px',
              }}
            />
            <span
              style={{
                margin: '0 0 0 25px',
                fontFamily: 'IBM Plex Sans',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '18px',
                letterSpacing: '0px',
              }}
            >
              Filtr
            </span>
          </button>
          <button
            type="button"
            onClick={() => {
              setArchieve((prev) => !prev)
              setPage(1)
              setSearchFields((prev) => ({ ...prev, statuses: [] }))
            }}
            className={`${archieve ? 'active' : ''} ClientFiltrButton`}
          >
            <span
              style={{
                fontFamily: 'IBM Plex Sans',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '18px',
                letterSpacing: '0px',
              }}
            >
              {archieve ? 'Faol' : 'Arxiv'}
            </span>
          </button>
          <Typography
            className="filterFieldTitle"
            style={{
              cursor: 'default',
            }}
          >
            Shartnomalar soni:
            <NumberFormat
              style={{ margin: '0 10px' }}
              value={contracts?.data?.totalElements}
              displayType={'text'}
              thousandSeparator={' '}
              prefix={''}
            />
            ta
          </Typography>
        </Space>
      </Content>
      {filter && (
        <div>
          <Row style={{ width: '1050px' }} gutter={[36, 24]}>
            <Col span={6} style={{ margin: '15px 0' }}>
              <h1 className="filterFieldTitle">Sana:</h1>
              <RangePicker
                id="time"
                name="date"
                format="DD.MM.YYYY"
                placeholder={['boshlanish', 'tugash']}
                onChange={(dates, dateStrings) => {
                  setSearchFields({
                    ...searchFields,
                    start: dateStrings[0],
                    finish: dateStrings[1],
                  })
                }}
              />
            </Col>
            <Col span={4} style={{ margin: '15px 0' }}>
              <h1 className="filterFieldTitle">Status:</h1>
              <Select
                mode="multiple"
                name="status"
                showArrow
                allowClear
                style={{ width: '100%' }}
                options={statusOptions()}
                onChange={(e) => {
                  setSearchFields({
                    ...searchFields,
                    statuses: e,
                  })
                }}
                value={searchFields.statuses || []}
              />
            </Col>
            <Col style={{ margin: '15px 0' }}>
              <h1 className="filterFieldTitle">Turi:</h1>
              <Select
                mode="multiple"
                style={{ minWidth: '10rem' }}
                onChange={(e) => {
                  setSearchFields({
                    ...searchFields,
                    types: e,
                  })
                }}
              >
                <Select.Option value="SPECIFIC">Maxsus</Select.Option>
                <Select.Option value="SIMPLE">Oddiy</Select.Option>
              </Select>
            </Col>
          </Row>
        </div>
      )}
      <ContractTable
        isFetching={contracts?.isFetching}
        AllData={contracts?.data?.contracts || []}
        spinner={contracts.isLoading}
      />
      <Pagination
        current={contracts?.data?.currentPage}
        pageSize={'10'}
        showSizeChanger={false}
        total={contracts?.data?.totalElements}
        onChange={(e) => {
          setPage(e)
        }}
      />
      <Drawer
        title="Tezkor qidiruv ishlatish bo`yicha yo`riqnoma"
        placement="right"
        width={355}
        closable={false}
        onClose={() => {
          setDrableOpen(false)
        }}
        visible={drableOpen}
      >
        <p>Tezkor qidiruv ishlatish bo`yicha yo`riqnoma:</p>
        <p>
          Teskor qidiruv mijoz ismi, familiyasi va otasinig ismi agar mijoz
          yuridik shaxs bo'lsa nomi bo'yicha ishlaydi
        </p>
        <p>Misol: Sara Yuldasheva Ochilovna</p>
        <p>Eslatma:</p>
        <p>
          Qidiruv amalga oshishi mijoz haqida na'munadagi bitta ma'lumot ham
          yetarli va qidiruv uchun Enter tugmasi bosilishi kerak
        </p>
      </Drawer>
    </div>
  )
}

export default Contract
