import React, { useRef } from 'react'
import { Popconfirm, Spin, Table, Tag } from 'antd'
import Column from 'antd/es/table/Column'
import NumberFormat from 'react-number-format'
import { FaRegNewspaper } from 'react-icons/fa'
import { GiCancel } from 'react-icons/gi'
import { LoadingOutlined } from '@ant-design/icons'
import {
  useCancelPayment,
  useDownloadPaymentMutation,
} from '../../queries/mutations'
const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 12, marginLeft: '10px', color: '#5f5e5e' }}
    spin
  />
)

function TablePayments({ spinner, data, isFetching }) {
  const cancelPaymentMutation = useCancelPayment()
  const loadingId = useRef(-1)

  const downloadPayment = useDownloadPaymentMutation()

  // cancel payment
  const cancelPayment = (paymentId) => {
    cancelPaymentMutation.mutate(paymentId)
  }

  return (
    <Table
      showSizeChanger={true}
      loading={spinner}
      size={'middle'}
      dataSource={data}
      pagination={false}
      rowClassName="editable-row"
      style={{ width: '100%' }}
    >
      <Column title="Raqami" dataIndex="number" key="number" />
      <Column
        title="Shartnoma"
        dataIndex="contractNumber"
        key="contractNumber"
      />
      <Column
        title={() => (
          <span>
            Sana
            {isFetching && <Spin indicator={antIcon} />}
          </span>
        )}
        dataIndex="createdDate"
        key="createdDate"
      />
      <Column title="Mijoz" key={'clientName'} dataIndex={'clientName'} />
      <Column
        title="Summa"
        width={'12%'}
        // dataIndex="amount"
        key="amount"
        render={(record) => (
          <>
            <NumberFormat
              value={record.amount}
              displayType={'text'}
              thousandSeparator={' '}
              prefix={''}
            />
            &nbsp;
            {record.contractAmountType}
          </>
        )}
      />
      <Column
        title="To'lov turi"
        dataIndex="type"
        key="type"
        render={(type) => (
          <Tag
            style={{
              width: '80px',
              textAlign: 'center',
            }}
            color={
              (type === 'TRANSFER' && '#7c30d9') ||
              (type === 'CARD' && '#2ecb38') ||
              (type === 'CASH' && '#ff7825') ||
              (type === 'BANK' && '#ff5cd5')
            }
            key={type}
          >
            {(type === 'TRANSFER' && 'P2P') ||
              (type === 'CARD' && 'Karta') ||
              (type === 'CASH' && 'Naqd') ||
              (type === 'BANK' && 'Bank') ||
              type}
          </Tag>
        )}
      />
      <Column title="Masul" key="employeeName" dataIndex={'employeeName'} />
      <Column
        render={(record) =>
          downloadPayment.isLoading && record.id === loadingId.current ? (
            <Spin />
          ) : (
            <div
              className="ClientTableIcon"
              onClick={() => {
                downloadPayment.mutate(record.id)
                loadingId.current = record.id
              }}
            >
              <FaRegNewspaper />
            </div>
          )
        }
        align="center"
      />
      <Column
        render={(record) => (
          <Popconfirm
            title="To'lovni bekor qilmoqchimisiz?"
            okText="Ha"
            cancelText="Yo'q"
            placement="left"
            onConfirm={() => cancelPayment(record.id)}
          >
            <div className="ClientTableIcon">
              <GiCancel />
            </div>
          </Popconfirm>
        )}
      />
    </Table>
  )
}

export default TablePayments
