import React from "react";
import AuthService from "../services/AuthService";
import {Route} from "react-router-dom";
import {Result} from "antd";

function RouteByRole(props) {
  let page;
  if (AuthService.hasAccess(props.page)) {
    page = <Route exact path={props.path + props.page}>{props.children}</Route>
  } else if (AuthService.hasAccess(props.page) !== undefined && !AuthService.hasAccess(props.page)) {
    page =
      <Route path={props.path + props.page}>
        <Result
          title="403"
          subTitle="Sizda bu sahifa uchun huquq yo`q!"
          icon={<img src={process.env.PUBLIC_URL + "/images/astronaut.png"} style={{width: 128, marginTop: 50}} alt={"logo"}/>}
          style={{backgroundColor: "#fafbfd"}}
        />
      </Route>;
  }

  return page;
}

export default RouteByRole;
