import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  Select,
  Switch,
  Spin,
} from 'antd'
import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import {
  useCompanyEditMutation,
  useCompanyMutation,
} from '../../../queries/mutations'
import { useEmployeeSelects, useOneCompany } from '../../../queries/queries'
import { apis } from '../../../http/apis'

const { Option } = Select

const { Item, useForm } = Form
const EditModal = ({ isOpen, setIsOpen, isCompany }) => {
  const [form] = useForm()
  const { push, location } = useHistory()
  const params = queryString.parse(location.search)
  const [isPasswordEditable, setIsPasswordEditable] = useState(false)

  const mutation = useCompanyMutation(apis.EMPLOYEES)
  const edit = useCompanyEditMutation(apis.EMPLOYEES)

  const { data, isLoading, isFetching } = useOneCompany(
    params.id,
    apis.EMPLOYEES
  )

  const { data: createData, isLoading: isCreateDataLoading } =
    useEmployeeSelects(isCompany)

  useEffect(() => {
    if (params.id && isOpen) {
      form.setFieldsValue({ ...data, roles: data?.roles[0] })
    }
  }, [params.id, form, data, isOpen])

  const onCancel = () => {
    form.resetFields()
    setIsOpen(false)
    delete params.id
    push(
      location.pathname + '?' + queryString.stringify(params) + location.hash
    )
  }

  const handleFinish = async (values) => {
    if (isCompany) {
      delete values.company
    }
    if (params.id) {
      if (!values.password) {
        delete values.password
      }
      await edit.mutateAsync({
        ...values,
        id: params.id,
        roles: [values.roles],
      })
    } else {
      await mutation.mutateAsync({
        ...values,
        roles: [values.roles],
        status: true,
      })
    }
    onCancel()
  }

  return (
    <Modal
      title={
        <Space>
          <Typography>Xodim qo‘shish</Typography>
          {isLoading || isFetching ? <Spin size="small" spin /> : null}
        </Space>
      }
      footer={false}
      visible={isOpen}
      onCancel={onCancel}
    >
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Row gutter="16">
          <Col span={12}>
            <Item
              required
              rules={[{ required: true, message: 'Bu maydonni to‘ldiring!' }]}
              label="F.I.O"
              name="fullName"
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              required={!params.id}
              rules={[
                {
                  required: !params.id,
                  message: 'Bu maydonni to‘ldiring!',
                },
              ]}
              label="Foydalanuvchi nomi"
              name="username"
            >
              {params.id ? data?.username : <Input />}
            </Item>
          </Col>
          <Col span={12}>
            <Item
              required={!params.id}
              rules={[
                {
                  required: !params.id,
                  message: 'Bu maydonni to‘ldiring!',
                },
                { min: 8, message: 'Minimal 8 ta simbol' },
              ]}
              label={
                <>
                  Parol
                  {params.id && (
                    <Button
                      size="small"
                      onClick={() => {
                        setIsPasswordEditable(!isPasswordEditable)
                      }}
                      type="link"
                    >
                      {isPasswordEditable ? 'yashirish' : "o'zgartirish"}
                    </Button>
                  )}
                </>
              }
              name="password"
            >
              {params.id ? (
                isPasswordEditable ? (
                  <Input.Password />
                ) : null
              ) : (
                <Input.Password />
              )}
            </Item>
          </Col>
          <Col span={12}>
            <Item
              required
              rules={[{ required: true, message: 'Bu maydonni to‘ldiring!' }]}
              label="Telefon"
              name="phone"
            >
              <Input />
            </Item>
          </Col>
          {!isCompany && (
            <Col span={12}>
              <Item
                required
                rules={[{ required: true, message: 'Bu maydonni to‘ldiring!' }]}
                label="Kompaniya Nomi"
                name="company"
              >
                <Select isLoading={isCreateDataLoading}>
                  {createData?.companies.map((option) => (
                    <Option value={option.id} key={option.value}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
          )}
          <Col span={12}>
            <Item
              required
              rules={[{ required: true, message: 'Bu maydonni to‘ldiring!' }]}
              label="Roli"
              name="roles"
            >
              <Select isLoading={isCreateDataLoading}>
                {createData?.roles.map((option) => (
                  <Option key={option.name} value={option.name}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row justify="end">
          <Item label={'Status'} name={'status'} valuePropName="checked">
            <Switch defaultChecked />
          </Item>
        </Row>
        <Row justify="end">
          <Space>
            <Button danger onClick={onCancel} type="primary">
              Bekor qilish
            </Button>
            <Button
              loading={mutation.isLoading || edit.isLoading}
              htmlType="submit"
              type="primary"
            >
              {params.id ? 'Saqlash' : 'Yaratish'}
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default EditModal
