import React from "react";
import {useRouteMatch} from "react-router-dom";
import Sidebar from "./Sidebar.js";
import Content from "./Content.js";

function Main() {
  let {path, url} = useRouteMatch();
  return (
    <div>
      <Sidebar url={url} path={path}/>
      <Content path={path}/>
    </div>
  );
}

export default Main;
