import React from 'react';
import {Spin, Table, Tag, Tooltip} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

const {Column} = Table
const antIcon = <LoadingOutlined style={{fontSize: 12, marginLeft: '10px', color: '#5f5e5e'}} spin/>

function MessageTable(props){
  const {spinner, data, pageNumber, isFetching} = props
  return (
    <div>
      <Table
        showSizeChanger={true}
        size={"middle"}
        rowClassName="editable-row"
        loading={spinner}
        dataSource={data}
        pagination={false}
      >
        <Column title="№" dataIndex="id" key="id" render={(id, data, index) => {
          let number = (index + 1) + 10 * (pageNumber - 1)
          return (
            <span>
              {number}
            </span>
          )
        }}/>
        <Column
          title={() => (
            <span>
              Mijoz
              {isFetching && <Spin indicator={antIcon} />}
            </span>
          )}
          key="clientFullName"
          dataIndex={'clientFullName'}
        />
        <Column title="Telefon raqami" dataIndex="phone" key="phone"/>
        <Column title="Xabar matni" key="text" dataIndex={"text"} render={text => (
          <Tooltip placement="top" title={text}>
            <span>
              {text?.slice (0, 15) +
              (text?.length > 15 ? '...' : '')}
            </span>
          </Tooltip>
        )}/>
        <Column title="Vaqti" dataIndex="sendDate" key="sendDate"/>
        {/*<Column title="Mas'ul" key="employee" render={record => {*/}
        {/*  let employee = "Tizim"*/}
        {/*  if (record.employeeFirstName) {*/}
        {/*    employee = record.employeeFirstName + " " + record.employeeLastname*/}
        {/*  }*/}
        {/*  return (*/}
        {/*    <span>*/}
        {/*      {employee}*/}
        {/*    </span>*/}
        {/*  )*/}
        {/*}}/>*/}
        <Column
          title="Status"
          dataIndex="status"
          key="send"
          render={status => (
            <Tag
              style={{
                width: "110px",
                textAlign: 'center'
              }}
              color={(status === "WILL_BE_SENT" && "#7c30d9") || (status === "IS_BEING_SENT" && "#ff7825") || (status === "WAS_SENT" && "#2ecb38") || (status === "NOT_SENT" && "#F60A0AFF") || (status === "WRONG_MESSAGE" && "#3B0000FF") || '#3B0000FF'}
              key={status}>
              {(status === "WILL_BE_SENT" && "Yuboriladi") || (status === "IS_BEING_SENT" && "Yuborilmoqda") || (status === "WAS_SENT" && "Yuborildi") || (status === "NOT_SENT" && "Yuborilmagan") || (status === "WRONG_MESSAGE" && "Xato ma'lumot") || status}
            </Tag>
          )}/>
      </Table>
    </div>
  );
}

export default MessageTable;