import { Button, Input, Menu, message, Typography } from 'antd'
import React, { useState } from 'react'
import { useSendAllSmsMutation } from '../../../queries/mutations'

const { TextArea } = Input

const AllMessage = () => {
  const [messageText, setMessageText] = useState('')

  const sendMessage = useSendAllSmsMutation()

  const sendMessageFunc = () => {
    if (messageText) {
      sendMessage.mutate(messageText)
    } else {
      message.error('Xabar matni kiritilmagan!')
    }
  }

  return (
    <div
      style={{
        background: '#FFFFFF',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
        borderRadius: 6,
        padding: 24,
      }}
    >
      <Typography className="ClientAddHeader">Umumiy xabar yuborish</Typography>
      <Menu
        defaultActiveFirst={'toAll'}
        mode="horizontal"
        style={{
          textAlign: 'center',
        }}
      >
        <Menu.Item key="toAll">Hamma mijozlarga</Menu.Item>
      </Menu>
      <div
        style={{
          marginTop: '20px',
          marginLeft: '40px',
          display: 'flex',
        }}
      >
        <label className="SettingsMessageMainLabel" htmlFor="message">
          Xabar matni
        </label>
        <TextArea
          id={'message'}
          rows={8}
          placeholder="Xabar matni..."
          value={messageText}
          onChange={(e) => {
            setMessageText(e.target.value)
          }}
          style={{
            marginLeft: '47px',
            resize: 'none',
            width: '350px',
          }}
        />
        <Button
          loading={sendMessage.isLoading}
          onClick={sendMessageFunc}
          type="primary"
          style={{
            margin: '0 10px',
          }}
        >
          Yuborish
        </Button>
      </div>
    </div>
  )
}

export default AllMessage
