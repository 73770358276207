import {
  CONTRACT_OPEN_PAGE,
  CONTRACT_DATE,
  CONTRACT_SAVE
} from './types'
import moment from "moment";

const initialState = {
  openPage: 1,
  date: moment(new Date()).format('DD.MM.YYYY HH:mm:ss'),
  savedContract: null
}

const contractReducer = (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {
    case CONTRACT_OPEN_PAGE:
      return {
        ...state,
        openPage: payload,
      }
    case CONTRACT_DATE:
      return {
        ...state,
        date: payload,
      }
    case CONTRACT_SAVE:
      return {
        ...state,
        savedContract: payload,
      }
    default:
      return state
  }
}

export default contractReducer
