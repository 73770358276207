import React from 'react'

const ProductsList = ({ data }) => {
  return (
    <>
      {data?.map((item) => (
        <div className="productListCont" key={item?.id}>
          <div>
            <p className={'productListLabel'}>Nomi:</p>
            <p className={'productListName'}>{item?.productDto?.name}</p>
          </div>
          <div>
            <p className={'productListLabel'}>Miqdori:</p>
            <p className={'productListName'}>{item?.count?.toLocaleString('ru')}</p>
          </div>
          <div>
            <p className={'productListLabel'}>Narxi:</p>
            <p className={'productListName'}>
              {item?.productDto?.price?.toLocaleString('ru')} UZS
            </p>
          </div>
          <div>
            <p className={'productListLabel'}>Umumiy summasi:</p>
            <p className={'productListName'}>
              {(item?.productDto?.price * item?.count)?.toLocaleString('ru')} UZS
            </p>
          </div>
        </div>
      ))}
    </>
  )
}

export default ProductsList
