export const apis = {
  ROLES: '/auth/role',
  COMPANY_SELECT: '/company/select',
  EMPLOYEES: '/employee',
  LOG_IN: '/auth/sign-in',
  CONTRACTS_ALL: '/contract/all',
  CONTRACTS: '/contract',
  MONTHLY_PAYMENTS: '/monthly-payment',
  PAYMENTS: '/payment',
  PAYMENTS_ALL: '/payment/all',
  PAYMENTS_ALL_EXCEL: '/payment/all/excel',
  PAYMENTS_SUM: '/payment/sum',
  PAYMENTS_SUM_TODAY: '/payment/getSumPayments',
  ARREARAGE_LIST: '/arrearage/list',
  ARREARAGE: '/arrearage',
  ARREARAGE_ALL: '/arrearage/all',
  CLIENT: '/client',
  MESSAGES_ALL: '/send-message/all',
  MESSAGE: '/send-message',
  MESSAGE_TEXT: '/message/get',
  MESSAGE_TEMPLATE: '/message-template',
  MESSAGE_CHANGE_TEXT: '/message/save',
  SEND_MESSAGE_ALL_CLIENTS: '/phone/send2all?message=',
  CONTRACT: '/contract',
  BUILDING_COMPANY: '/company',
  CONFIG: '/config',
  PAYMENT: '/payment',
  WORD: '/word',
  EXCEL: '/excel',
  PRODUCT: '/product',
  CARD: '/card',
  UZCARD: '/uz-card',
  CREATE_SPECIAL_CONTRACT: '/contract/specific',
  PRODUCT_SEARCH: '/product?name=',
  PARTS: '/parts',
}

export const SEARCH_BY_PASSPORT_CLIENT =
  'https://api.online-mahalla.uz/api/v1/public/tax/passport'
