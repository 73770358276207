import React, { useEffect, useState } from 'react'
import { DatePicker, message, Table, Typography } from 'antd'

import moment from 'moment'
import { IoMdCopy, MdContentPaste } from 'react-icons/all'
import NumberFormat from 'react-number-format'
import GlobalAssistant from '../../../services/GlobalAssistant'
import { createPaymentTable, setTableCount } from '../../../redux/actions'
import { connect } from 'react-redux'

const { Column } = Table

function PaymentTable(props) {
  const [monthlyPayments, setMonthlyPayments] = useState(null)
  const [copiedValue, setCopiedValue] = useState(null)
  const [resideuInside, setResideuInside] = useState(0)
  const {
    createPaymentTable,
    delay,
    range,
    firstDate,
    residue,
    setTableCount,
  } = props
  useEffect(() => {
    calculateMonthlyPayment(delay, residue, firstDate, range)
    // eslint-disable-next-line
  }, [delay, range, firstDate, residue])

  const calculateTotalCount = () => {
    let totalCount = residue
    let dataSource = []
    for (let i = 0; i < parseInt(delay); i++) {
      let data = monthlyPayments[i]
      data.totalCount = totalCount
      totalCount -= data.oneMonthPay
      dataSource.push(data)
    }
    if (totalCount < 0) {
      message.error("Oylik to'lov miqdori shartnoma summasidan katta")
    }
    setTableCount(totalCount)
    setResideuInside(totalCount)
    createPaymentTable(dataSource)
    setMonthlyPayments(dataSource)
  }

  const calculateMonthlyPayment = (delay, price, firstDate, paymentDelay) => {
    let dataSource = []
    let totalCount = price
    let oneMonthPay = price / delay
    let addMonth = 0
    oneMonthPay = lastThousand(oneMonthPay)
    for (let month = 0; month < delay; month++) {
      if (totalCount - oneMonthPay < oneMonthPay) {
        oneMonthPay = totalCount
      }

      // if (delay - month === 1) {
      //   oneMonthPay = totalCount
      // }

      const oneMonthPayCounter =
        month === 0 && delay > 1
          ? oneMonthPay + Math.round((totalCount % delay) * 100) / 100
          : oneMonthPay

      let data = {
        number: month + 1,
        date: moment(firstDate, 'DD-MM-YYYY')
          .add(addMonth, 'months')
          .format('DD.MM.YYYY'),
        totalCount: totalCount,
        oneMonthPay: oneMonthPayCounter,
      }
      addMonth += paymentDelay
      totalCount -= oneMonthPayCounter
      dataSource.push(data)
    }
    setResideuInside(0)
    setTableCount(0)
    createPaymentTable(dataSource)
    setMonthlyPayments(dataSource)
  }

  const lastThousand = (x) => {
    // x = x * 10
    x = Math.floor(x)
    return x
  }

  const copyFunc = (value) => {
    setCopiedValue(value)
    message.success('Nusxa olindi!')
  }

  const changeOneMonthPay = (index, value) => {
    let newMonthlyPayments = [...monthlyPayments]
    newMonthlyPayments[index].oneMonthPay = value
    createPaymentTable(newMonthlyPayments)
    setMonthlyPayments(newMonthlyPayments)
    calculateTotalCount()
  }

  const pasteFunc = (index) => {
    if (copiedValue) {
      let newMonthlyPayments = [...monthlyPayments]
      newMonthlyPayments[index].oneMonthPay = copiedValue
      setMonthlyPayments(newMonthlyPayments)
      calculateTotalCount()
    } else {
      message.error('Nusxa olinmagan!')
    }
  }

  return (
    <>
      <Table
        showSizeChanger={true}
        size={'middle'}
        dataSource={monthlyPayments}
        pagination={false}
        rowClassName="editable-row"
      >
        <Column title={'№'} dataIndex={'number'} key={'number'} />
        <Column
          title="Sana"
          key="date"
          width={'11%'}
          render={(record) => {
            return (
              <DatePicker
                id={`date${record.number}`}
                allowClear={false}
                format="DD.MM.YYYY"
                value={moment(record.date, 'DD-MM-YYYY')}
                style={{ padding: '4px', width: '103%' }}
                onChange={(date, dateString) => {
                  let table = [...monthlyPayments]
                  table.forEach((item) => {
                    if (item.number === record.number) {
                      item.date = dateString
                    }
                  })
                  setMonthlyPayments(table)
                }}
              />
            )
          }}
        />
        <Column
          title="Qolgan summa"
          dataIndex="totalCount"
          key="totalCount"
          render={(record) => (
            <NumberFormat
              value={record}
              displayType={'text'}
              thousandSeparator={' '}
              prefix={''}
            />
          )}
        />
        <Column
          title="To'lov summasi"
          key="phone"
          render={(text, record, index) => (
            <>
              <NumberFormat
                className={'inputNumberStyle'}
                thousandSeparator={' '}
                style={{
                  borderColor: record.oneMonthPay ? null : 'red',
                }}
                value={record.oneMonthPay}
                onChange={(e) => {
                  let val = GlobalAssistant.deleteSpace(e.target.value)

                  changeOneMonthPay(index, +val ? +val : null)
                }}
              />
              <Typography
                style={{
                  fontFamily: 'IBM Plex Sans, serif',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: 'o.8vw',
                  color: 'red',
                  display: record.oneMonthPay ? 'none' : 'block',
                }}
              >
                Summa kiritilmagan
              </Typography>
            </>
          )}
        />
        <Column
          title="Nusxalash"
          key="copy"
          width={'5%'}
          render={(record) => (
            <IoMdCopy
              className={'monthlyPaymentsCopyPastIcon'}
              onClick={() => {
                copyFunc(record.oneMonthPay)
              }}
            />
          )}
        />
        <Column
          title="Qo'yish"
          key="paste"
          width={'5%'}
          render={(text, record, index) => (
            <MdContentPaste
              onClick={() => pasteFunc(index)}
              className={'monthlyPaymentsCopyPastIcon'}
            />
          )}
        />
      </Table>
      <Typography
        className={'bossHousesInfoFlatsPersentSecond'}
        style={{
          margin: '10px',
        }}
      >
        <span style={{ paddingRight: '10px' }}>Jadvalning umumiy summasi:</span>
        <NumberFormat
          value={residue}
          displayType={'text'}
          thousandSeparator={' '}
          prefix={''}
        />
      </Typography>
      <Typography
        className={'bossHousesInfoFlatsPersentSecond'}
        style={{
          margin: '10px',
        }}
      >
        <span style={{ paddingRight: '10px' }}>Qoldiq summa</span>
        <NumberFormat
          value={resideuInside}
          displayType={'text'}
          thousandSeparator={' '}
          prefix={''}
        />
      </Typography>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    delay: state.paymentCont.delay,
    range: state.paymentCont.range,
    firstDate: state.paymentCont.firstDate,
    residue: state.paymentCont.residue,
  }
}

const mapDispatchToProps = {
  createPaymentTable,
  setTableCount,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTable)
