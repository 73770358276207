import { Button, Table, Tag, Typography } from 'antd'
import React from 'react'
import { BiEditAlt } from 'react-icons/all'
import { useQueryString } from '../../../hooks/useQueryString'

const { Column } = Table

const TableCompanyEmployees = ({ data, isLoading, setIsOpen }) => {
  const { push, query } = useQueryString()

  return (
    <>
      <Typography>Xodimlar</Typography>
      <Table
        //
        loading={isLoading}
        showSizeChanger={true}
        size={'middle'}
        pagination={false}
        dataSource={data}
        rowKey={(record) => record.id}
        style={{ width: '100%', marginTop: '30px', paddingBottom: '3px' }}
        rowClassName="editable-row"
      >
        <Column
          title="#"
          dataIndex="id"
          key="id"
          render={(_, __, index) => (parseInt(query.page) - 1) * 6 + index + 1}
        />
        <Column title="F.I.O" key="fullName" dataIndex="fullName" />
        <Column title="Telefon" dataIndex="phone" key="role" />
        <Column
          title="Status"
          dataIndex="status"
          key="role"
          render={(status) => (
            <span>
              {status ? (
                <Tag color="#87d068">active</Tag>
              ) : (
                <Tag color="#f50">deaktiv</Tag>
              )}
            </span>
          )}
        />
        <Column title="Kompaniya" dataIndex="company" key="company" />
        <Column
          title="Actions"
          key="actions"
          render={(data) => (
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => {
                  push('id', data.id)
                  setIsOpen(true)
                }}
                className={'developerTableCompanyActionButtons'}
                type="primary"
                icon={<BiEditAlt />}
              />
            </div>
          )}
        />
      </Table>
    </>
  )
}

export default TableCompanyEmployees
