import { Button, Table } from 'antd'
import React, { useState } from 'react'
import ImgEmployee from './ImgEmployee'
import EditModal from '../../developer/companyEmployees/EditModal'
import { useBuilderCompany } from '../../../queries/queries'
import { apis } from '../../../http/apis'
import { useQueryString } from '../../../hooks/useQueryString'

const { Column } = Table

const Employee = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { data, isLoading } = useBuilderCompany(apis.EMPLOYEES)
  const { push, query } = useQueryString()

  return (
    <div
      style={{
        background: '#FFFFFF',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
        borderRadius: 6,
        padding: 24,
      }}
    >
      <Table
        loading={isLoading}
        pagination={{
          pageSize: 6,
          current: parseInt(query.page) || 1,
          total: data?.totalElements,
          onChange: (page) => push('page', page),
        }}
        dataSource={data?.employees}
        rowKey="id"
        onRow={(record) => {
          return {
            onClick: () => {
              push('id', record.id)
              setIsOpen(true)
            },
          }
        }}
      >
        <Column
          title="#"
          dataIndex="id"
          key="id"
          render={(_, __, index) =>
            ((typeof query.page === 'number'
              ? query.page || 1
              : parseInt(query.page || '1')) -
              1) *
              6 +
            index +
            1
          }
        />
        <Column render={() => <ImgEmployee id={null} />} />
        <Column
          title="Ismi"
          dataIndex="fullName"
          // render={(record) => record.lastname + '  ' + record.firstname}
        />
        <Column
          title="Darajasi"
          render={(record) => {
            return <span>{record.roleId}</span>
          }}
        />
        <Column title="Telefon raqami" key="phone" dataIndex="phone" />
      </Table>
      <Button
        type="primary"
        onClick={() => {
          setIsOpen(true)
        }}
      >
        Qo'shish
      </Button>
      <EditModal isOpen={isOpen} setIsOpen={setIsOpen} isCompany />
    </div>
  )
}

export default Employee
