import React from 'react'

const ArrowTop = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.98827 9H3.90345C1.31886 9 0.25691 7.43236 1.55311 5.51783L2.59944 3.97687L3.64577 2.43589C4.94194 0.521369 7.05802 0.521369 8.35423 2.43589L9.40056 3.97687L10.4469 5.51783C11.7431 7.43236 10.6811 9 8.09655 9H5.98827Z"
        stroke="black"
        strokeOpacity="0.85"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowTop
