import { SearchOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Typography,
} from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import CalendarIcon from '../../../icons/CalendarIcon'
import { useSearchClientBuPassportData } from '../../../queries/queries'
import {
  addClientPhone,
  checkClientPhone,
  delateClientPhone,
} from '../../../redux/actions'
import { contractTypes } from '../../../utils/constants/contractTypes'
import { validateDateString } from '../../../utils/helper/validateDateString'
import SponsorCont from './SponsorCont'

function PersonCont({ form }) {
  const dispatch = useDispatch()
  const [searchClient, setSearchClient] = useState({
    passportSerialAndNumber: '',
    dateBirth: '',
  })
  const [enableSponsor, setEnableSponsor] = useState(false)
  const { isLoading, data } = useSearchClientBuPassportData(
    searchClient.passportSerialAndNumber,
    searchClient.dateBirth
  )

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        fullName:
          data?.sur_name + ' ' + data?.name + ' ' + data?.patronymic_name,
        address: data?.address,
        passportDate: moment(data?.given_date, 'YYYY-MM-DD'),
        passportPlace: data?.given_place,
      })
    }
  }, [data, form])

  const clientData = useSelector((state) => state.clientCont.clientData)
  const clientPhones = useSelector((state) => state.clientCont.clientPhones)

  useEffect(() => {
    if (clientData) {
      let fieldObj = {}
      Object.keys(clientData).forEach((data) => {
        if (Array.isArray(clientData[data])) {
          clientData[data].forEach((phn, index) => {
            fieldObj = { ...fieldObj, [`phones${index}`]: phn.phone }
          })
        } else if (clientData[data]) {
          fieldObj = { ...fieldObj, [data]: clientData[data] }
        }
      })
      form.setFieldsValue(fieldObj)
    } else {
      form.resetFields()
    }
    // eslint-disable-next-line
  }, [clientData])

  const handleSearch = () => {
    if (form.getFieldValue('passportData') && form.getFieldValue('dateBirth')) {
      setSearchClient({
        passportSerialAndNumber: form.getFieldValue('passportData'),
        dateBirth: form.getFieldValue('dateBirth'),
      })
    } else {
      message.error("Passport raqami yoki tug'ilgan kun kiritilmagan!")
    }
  }

  // enable add sponsor button
  const enableAddSponsor = () => {
    setEnableSponsor(true)
  }

  return (
    <>
      <div className="YuridikShFamCont">
        <Divider orientation="left">
          <Typography.Title level={5}>Mijoz ma'lumotlari</Typography.Title>
        </Divider>
        <Row gutter={[16, 0]}>
          <Col span={10}>
            <div className={'contractPlusInputsCont'}>
              <label htmlFor="passportData" className="YuridikShLabel">
                Passport seriyasi,raqami:
              </label>
              <Form.Item
                style={{ paddingLeft: '17px', width: '100%' }}
                name={'passportData'}
                rules={[
                  {
                    required: true,
                    message: 'seriyasi va raqami xato',
                  },
                ]}
              >
                <InputMask
                  mask="aa9999999"
                  maskChar=""
                  onChange={(e) => {
                    form.setFieldsValue({
                      passportData: e.target.value.toUpperCase(),
                    })
                  }}
                  id="passportData"
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      style={{
                        border: 'none',
                        borderBottom: '1px solid  #989FB9',
                        width: '200px',
                      }}
                    />
                  )}
                </InputMask>
              </Form.Item>
            </div>
          </Col>
          <Col span={10}>
            <div className={'contractPlusInputsCont'}>
              <label htmlFor="passportDate" className="YuridikShLabel">
                Tug'ilgan kun:
              </label>
              <Form.Item
                style={{ paddingLeft: '17px', width: '100%' }}
                name="dateBirth"
                rules={[
                  {
                    message: '',
                    len: 10,
                  },
                  {
                    validator: (rule, value) => {
                      if (value) {
                        form.setFieldsValue({
                          dateBirth: validateDateString(value),
                        })
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <InputMask
                  mask="99.99.9999"
                  // @ts-ignore*
                  maskChar={''}
                >
                  {(inputProps) => (
                    <Input
                      style={{
                        border: 'none',
                        borderBottom: '1px solid  #989FB9',
                        width: '180px',
                      }}
                      suffix={<CalendarIcon />}
                      placeholder={'kk.oo.yyyy'}
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </Form.Item>
            </div>
          </Col>
          <Col span={4}>
            <Button
              onClick={handleSearch}
              loading={isLoading}
              style={{ marginLeft: '48px' }}
              type={'primary'}
            >
              <SearchOutlined />
            </Button>
          </Col>
        </Row>
        <div style={{ paddingTop: '5px' }} className={'contractPlusInputsCont'}>
          <label htmlFor="fullName" className="YuridikShLabel">
            F.I.SH:
          </label>
          <Form.Item
            name="fullName"
            rules={[
              {
                required: true,
                message: 'Familiya kiritilmagan!',
              },
            ]}
          >
            <Input
              id="fullName"
              style={{
                border: 'none',
                borderBottom: '1px solid  #989FB9',
                width: '500px',
                marginLeft: '17px',
              }}
            />
          </Form.Item>
        </div>
        <div style={{ paddingTop: '5px' }} className={'contractPlusInputsCont'}>
          <label htmlFor="address" className="YuridikShLabel">
            Manzil:
          </label>
          <Form.Item
            name="address"
            rules={[
              {
                required: true,
                message: 'Manzil kiritilmagan!',
              },
            ]}
          >
            <Input
              id="address"
              style={{
                border: 'none',
                borderBottom: '1px solid  #989FB9',
                width: '500px',
                marginLeft: '17px',
              }}
            />
          </Form.Item>
        </div>
        {clientPhones.map((phone, index) => (
          <div key={index} style={{ display: 'flex' }}>
            {index === 0 ? (
              <label htmlFor="Phone" className="YuridikShLabel">
                Tel. raqami:
              </label>
            ) : (
              <label
                htmlFor="Phone"
                className="YuridikShLabel"
                style={{ color: 'transparent' }}
              />
            )}
            <Form.Item
              name={'phones' + index + ''}
              rules={[
                {
                  required: true,
                  message: 'Telefon raqam kiritilmagan!',
                  len: 13,
                },
              ]}
            >
              <InputMask
                style={{
                  border: 'none',
                  borderBottom: '1px solid  #989FB9',
                  width: '130px',
                  marginLeft: '17px',
                }}
                mask="+999999999999"
                maskChar=""
                id="Phone"
              >
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </Form.Item>
            <Checkbox
              checked={phone.active}
              onChange={() => {
                dispatch(checkClientPhone(index))
              }}
              style={{
                marginLeft: index === 0 ? '15px' : '18px',
                display: 'inline-block',
              }}
            />
            {index === 0 ? (
              <>
                {clientPhones.length !== 1 && (
                  <span
                    className="YuridikRemoveButton"
                    onClick={() => {
                      dispatch(delateClientPhone(index))
                    }}
                  >
                    <span
                      style={{
                        position: 'absolute',
                        display: 'block',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-55%)',
                      }}
                    >
                      -
                    </span>
                  </span>
                )}
                <span
                  className="YuridikAddButton"
                  onClick={() => {
                    dispatch(addClientPhone())
                  }}
                >
                  <span
                    style={{
                      position: 'absolute',
                      display: 'inline-block',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%,-55%)',
                    }}
                  >
                    +
                  </span>
                </span>
              </>
            ) : (
              <span
                className="YuridikRemoveButton"
                onClick={() => {
                  dispatch(delateClientPhone(index))
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    display: 'inline-block',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-55%)',
                  }}
                >
                  -
                </span>
              </span>
            )}
          </div>
        ))}
        <div className={'contractPlusInputsCont'}>
          <label htmlFor="passportDate" className="YuridikShLabel">
            Passport berilgan sana:
          </label>
          <Form.Item
            style={{ paddingLeft: '17px', width: '100%' }}
            name={'passportDate'}
            rules={[
              {
                required: true,
                message: 'Berilgan sanasi kiritilmagan',
              },
            ]}
          >
            <DatePicker
              format="DD.MM.YYYY"
              style={{
                border: 'none',
                borderBottom: '1px solid  #989FB9',
                width: '200px',
              }}
            />
          </Form.Item>
        </div>
        <div className={'contractPlusInputsCont'}>
          <label htmlFor="passportPlace" className="YuridikShLabel">
            Passport berilgan joy:
          </label>
          <Form.Item
            style={{ paddingLeft: '17px', width: '100%' }}
            name={'passportPlace'}
            rules={[
              {
                required: true,
                message: 'Berilgan joyi kiritilmagan',
              },
            ]}
          >
            <Input
              style={{
                border: 'none',
                borderBottom: '1px solid  #989FB9',
                width: '200px',
              }}
            />
          </Form.Item>
        </div>
        {enableSponsor && <SponsorCont type={contractTypes.SIMPLE} />}
        {!enableSponsor && (
          <div className="contractPlusInputsCont">
            <label
              className="YuridikShLabel cur-p txt-btn"
              onClick={enableAddSponsor}
            >
              Kafil qo'shish
            </label>
          </div>
        )}
      </div>
    </>
  )
}

export default PersonCont
