import { Modal, Table } from 'antd'
import React from 'react'

const { Column } = Table
const SponsorsModal = ({ visible, setVisible, sponsors }) => {
  // handle cancel
  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      centered
      footer={null}
      width={1000}
    >
      <Table dataSource={sponsors} pagination={false}>
        <Column title="N" render={(_, __, index) => index + 1} />
        <Column title="Ism" dataIndex="fullName" />
        <Column title="Manzil" dataIndex="address" />
        <Column title="Pasport" dataIndex="passportData" />
        <Column title="Telefon raqam" dataIndex="phone" />
      </Table>
    </Modal>
  )
}

export default SponsorsModal
