import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Switch,
  Typography,
} from 'antd'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import {
  useCompanyEditMutation,
  useCompanyMutation,
} from '../../../queries/mutations'
import queryString from 'query-string'
import { useOneCompany } from '../../../queries/queries'
import { apis } from '../../../http/apis'
const { useForm, Item } = Form

const EditModal = ({ open, close, isCompany }) => {
  const fields = [
    // {
    //   name: 'name',
    //   label: 'Nomi',
    //   required: true,
    //   span: 24,
    // },
    {
      name: 'accountNumber',
      label: 'Hisob raqami',
      required: true,
    },
    {
      name: 'address',
      label: 'Manzili',
      required: true,
    },
    {
      name: 'phone',
      label: 'Telefon raqami',
      required: true,
      placeholder: '+998991112255',
    },
    {
      name: 'bank',
      label: 'Bank',
      required: true,
    },
    {
      name: 'director',
      label: 'Direktor',
      required: true,
    },
    {
      name: 'inn',
      label: 'INN',
      required: true,
    },
    {
      name: 'mfo',
      label: 'MFO',
      required: true,
    },
    {
      name: 'oked',
      label: 'OKED',
      required: true,
    },
    {
      name: 'smsOriginator',
      label: 'SMS originator',
      required: false,
    },
    {
      name: 'smsUrl',
      label: 'SMS url',
      required: false,
    },
    {
      name: 'smsUsername',
      label: 'SMS Foydalanuvchi',
      required: false,
    },
    {
      name: 'smsPassword',
      label: 'SMS parol',
      required: false,
    },
    {
      name: 'cashId',
      label: 'Kassa Id',
      required: false,
    },
    {
      name: 'cashKey',
      label: 'Kassa kaliti',
      required: false,
    },
    {
      name: 'uzCardUsername',
      label: 'Uzcard username',
      required: false,
    },
    {
      name: 'uzCardPassword',
      label: 'Uzcard password',
      required: false,
    },
  ]

  const [form] = useForm()
  const { push, location } = useHistory()
  const params = queryString.parse(location.search)

  const mutation = useCompanyMutation(apis.BUILDING_COMPANY)
  const edit = useCompanyEditMutation(apis.BUILDING_COMPANY)
  const { data, isLoading, isFetching } = useOneCompany(params.id || 0)

  useEffect(() => {
    if (open && data) {
      form.setFieldsValue(data)
    }
  }, [params.id, form, data, open])

  const onCancel = () => {
    form.resetFields()
    close()
    delete params.id
    push(
      location.pathname + '?' + queryString.stringify(params) + location.hash
    )
  }

  const handleFinish = async (values) => {
    if (params.id) {
      await edit.mutateAsync({ ...values, id: params.id })
    } else {
      if (isCompany) {
        await edit.mutateAsync({
          ...values,
          id: data.id,
          status: data.status,
        })
      } else {
        await mutation.mutateAsync({ ...values, status: true })
      }
    }
    onCancel()
  }

  return (
    <Modal
      forceRender
      title={
        <Space>
          <Typography>Kompaniya qo‘shish</Typography>
          {isLoading || isFetching ? <Spin size="small" spin /> : null}
        </Space>
      }
      centered
      footer={false}
      visible={open}
      onCancel={onCancel}
    >
      <Form onFinish={handleFinish} form={form} layout="vertical">
        <Row gutter="16">
          <Col span={isCompany ? 24 : 20}>
            <Item
              required={true}
              label={'Nomi'}
              name={'name'}
              rules={[{ required: true, message: 'Bu maydonni to‘ldiring!' }]}
            >
              <Input />
            </Item>
          </Col>
          {isCompany ? null : (
            <Col span={4}>
              <Item label={'Status'} name={'status'} valuePropName="checked">
                <Switch defaultChecked />
              </Item>
            </Col>
          )}
          {fields.map((field) => {
            return (
              <Col span={field.span || 12} key={field.name}>
                <Item
                  required={field.required}
                  label={field.label}
                  name={field.name}
                  rules={[
                    {
                      required: field.required,
                      message: 'Bu maydonni to‘ldiring!',
                    },
                  ]}
                >
                  <Input placeholder={field.placeholder} />
                </Item>
              </Col>
            )
          })}
        </Row>
        <Row justify="end">
          <Space>
            <Button danger onClick={onCancel} type="primary">
              Bekor qilish
            </Button>
            <Button
              loading={mutation.isLoading || edit.isLoading}
              htmlType="submit"
              type="primary"
            >
              {params.id ? 'Saqlash' : isCompany ? 'Saqlash' : 'Yaratish'}
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default EditModal
