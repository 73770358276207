import axios from 'axios'
import AuthService from '../services/AuthService'

// export const API_URL = 'https://backend.muto.uz/v1'
export const API_URL = 'https://service.app.muto.uz/v1'

// export const API_URL = 'http://localhost:2707/v1'

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
})

$api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

$api.interceptors.response.use(undefined, async (err) => {
  const originalRequest = err?.config
  if (err?.response?.status === 401 && err.config && !err.config._isRetry) {
    originalRequest._isRetry = true
    try {
      const response = await axios.get(`${API_URL}/auth/refresh`, {
        withCredentials: true,
      })
      localStorage.setItem('token', response.data?.data?.accessToken)
      return $api.request(originalRequest)
    } catch (e) {
      AuthService.logout()
      window.location.replace(window.location.origin)
    }
  }
  throw err
})

export default $api
