class GlobalAssistant {
  deleteSpace(value) {
    return value.replace(/ /g, '')
  }
  addThousandSeparatorAndDeleteElements(value) {
    value = value.toString().replace(/[^0-9]/g, '')
    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return value
  }

  addThousandSeparator(value) {
    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return value
  }

  deleteNunNumbers(value) {
    value = value.toString().replace(/[^\d.]/g, '')
    return value
  }
}

export default new GlobalAssistant()
