import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Tag,
  Typography,
} from 'antd'
import { BiMessageCheck } from 'react-icons/bi'
import { useQueryClient } from 'react-query'
import { useOneContractInfo, usePartsOfContract } from '../../queries/queries'
import {
  useCancelContractMutation,
  useCreateContractParts,
  useDownloadContract,
  useDownloadMonthlyPaymentMutation,
  useDownloadPaymentMutation,
  useDownloadPaymentsExcel,
  usePaymentMutation,
} from '../../queries/mutations'
import { LoadingOutlined } from '@ant-design/icons'
import { AiFillPrinter } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import TableNextPayment from '../paymentPlus/TableNextPayment'
import CountGraphTable from './CountGraphTable'
import ProductsList from './ProductsList'
import { queryNames } from '../../queries/queryNames'
import { contractSave } from '../../redux/actions'
import moment from 'moment'
import PartsOfContract from './PartsOfContract'
import PartsOfContractModal from './PartsOfContractModal'
import { modalTypes } from '../../utils/constants/modalTypes'
import SponsorsModal from './SponsorsModal'

const { Title } = Typography
const { Option } = Select

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 12, marginLeft: '10px', color: '#5f5e5e' }}
    spin
  />
)

function ContractIdInfo() {
  const { id } = useParams()
  const history = useHistory()
  const [monthlyPaymentsTableModal, setMonthlyPaymentsTableModal] =
    useState(false)
  const [payType, setPayType] = useState('')

  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const downloadContract = useDownloadContract()
  const downloadMonthlyPayments = useDownloadMonthlyPaymentMutation()
  const downloadPayments = useDownloadPaymentsExcel()
  const deleteContract = useCancelContractMutation(id)

  const successPayment = () => {
    queryClient.invalidateQueries([queryNames.CONTRACTS, queryNames.INFO, id])
  }

  const downloadWordOnePayment = useDownloadPaymentMutation()
  const paymentCreate = usePaymentMutation(
    successPayment,
    false,
    downloadWordOnePayment
  )
  const [partsModalVisible, setPartsModalVisible] = useState(false)
  const [sponsorsModalVisible, setSponsorsModalVisible] = useState(false)
  const createPartMutation = useCreateContractParts(setPartsModalVisible)
  const { data: partsData } = usePartsOfContract(id)
  const { isLoading, data, isFetching } = useOneContractInfo(id)
  const [partId, setPartId] = useState(null)

  const payFirstPaymentFunc = () => {
    if (!payType) {
      message.error("To'lov turi tanlanmagan")
    } else {
      paymentCreate.mutate({
        id,
        paymentBody: {
          amount: data?.contract?.firstPayment - data?.contract?.payed,
          type: payType,
          createdDate: moment(new Date()).format('DD.MM.YYYY HH:mm:ss'),
        },
      })
    }
  }

  const goToPaymentPlusPageFunc = () => {
    dispatch(contractSave(data?.contract))
    history.push('/main/paymentPlus')
  }

  // create part
  const createPart = () => {
    createPartMutation.mutate({
      data: {
        products: data?.products?.map((product) => ({
          count: product.count,
          productDto: product.productDto,
        })),
      },
      contractId: id,
      type: modalTypes.CREATE,
      firstTime: true,
    })
  }

  // open sponsors modal
  const openSponsorsModal = () => {
    setSponsorsModalVisible(true)
  }

  return (
    <>
      {isLoading ? (
        <Spin
          style={{
            marginTop: '40px',
          }}
        />
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <Title
              style={{
                fontFamily: 'IBM Plex Sans',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '24px',
                lineHeight: '100%',
                color: '#989FB9',
                marginTop: '30px',
                marginBottom: '30px',
                cursor: 'default',
              }}
            >
              Shartnoma № {data?.contract?.number}
              {isFetching && <Spin indicator={antIcon} />}
            </Title>
            {(data?.contract?.status === 'STARTED' ||
              data?.contract?.status === 'ACTIVE') && (
              <Popconfirm
                placement="bottomLeft"
                title={'Shartnomani bekor qilishni tasdiqlash'}
                onConfirm={() => {
                  deleteContract.mutate()
                }}
                okText="Tasdiqlash"
                cancelText="Ortga qaytish"
              >
                <Button
                  type="link"
                  style={{
                    margin: '23px',
                    fontSize: '1.1rem',
                  }}
                >
                  Bekor qilish
                </Button>
              </Popconfirm>
            )}
            {/*{contractData?.status === "FINISHED" &&*/}
            {/*<div style={{margin: '30px'}}>*/}
            {/*  <span style={{*/}
            {/*    fontFamily: "IBM Plex Sans",*/}
            {/*    fontWeight: "normal",*/}
            {/*    fontSize: "18px",*/}
            {/*    color: "#989FB9",*/}
            {/*    cursor: 'default'*/}
            {/*  }}>*/}
            {/*    Faollashtirish:*/}
            {/*  </span>*/}
            {/*  <Switch*/}
            {/*    style={{margin: ' 0 5px 6px 10px'}}*/}
            {/*    disabled={contractData?.formal}*/}
            {/*    checked={contractData?.formal}*/}
            {/*    onChange={(e) => {*/}
            {/*      if (e)*/}
            {/*        formalizeContract ()*/}
            {/*    }}/>*/}
            {/*</div>}*/}
          </div>
          <Row>
            <Col span={12}>
              <div className="contractInfoClientCard">
                <div style={{ display: 'flex' }}>
                  <span className="oneContractLabel">
                    <span className={'oneContractLabelName'}>F.I.SH:</span>
                    <span title={data?.contract?.clientDto?.fullName}>
                      {data?.contract?.clientDto?.fullName.length > 35
                        ? data?.contract?.clientDto?.fullName?.slice(0, 35) +
                          ' ...'
                        : data?.contract?.clientDto?.fullName}
                    </span>
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  <span className="oneContractLabel">
                    <span className={'oneContractLabelName'}>Tel.raqam:</span>
                    <div>
                      {data?.contract?.clientDto?.clientPhones.map((phn) => (
                        <div
                          style={{
                            display: 'block',
                            marginLeft: '180px',
                          }}
                          key={phn.phone}
                        >
                          {phn.phone}
                          {phn.active && (
                            <BiMessageCheck
                              style={{
                                color: 'green',
                                paddingLeft: '5px',
                                fontSize: '18px',
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </span>
                </div>
                {data?.contract?.type === 'SIMPLE' && (
                  <div style={{ display: 'flex' }}>
                    <span className="oneContractLabel">
                      <span className={'oneContractLabelName'}>
                        Passport seriyasi:
                      </span>
                      {data?.contract?.clientDto?.passportData}
                    </span>
                  </div>
                )}
                {data?.contract?.type === 'SIMPLE' && (
                  <div style={{ display: 'flex' }}>
                    <span className="oneContractLabel">
                      <span className={'oneContractLabelName'}>
                        berilgan sana:
                      </span>
                      {data?.contract?.clientDto?.passportDate}
                    </span>
                  </div>
                )}
                {data?.contract?.type === 'SIMPLE' && (
                  <div style={{ display: 'flex' }}>
                    <span className="oneContractLabel">
                      <span className={'oneContractLabelName'}>
                        olingan joy:
                      </span>
                      {data?.contract?.clientDto?.passportPlace}
                    </span>
                  </div>
                )}
                <div style={{ display: 'flex' }}>
                  <span className="oneContractLabel">
                    <span className={'oneContractLabelName'}>Manzil:</span>
                    <span title={data?.contract?.clientDto?.address}>
                      {data?.contract?.clientDto?.address.length > 35
                        ? data?.contract?.clientDto?.address?.slice(0, 35) +
                          ' ...'
                        : data?.contract?.clientDto?.address}
                    </span>
                  </span>
                </div>
                {data?.contract?.sponsorDtoList && (
                  <div
                    title={'Word shartnoma yuklab olish'}
                    className="contractInfoPDFButton"
                    onClick={openSponsorsModal}
                  >
                    <span>Kafillarni ko'rish</span>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div
                  title={'Word shartnoma yuklab olish'}
                  className="contractInfoPDFButton"
                  onClick={() => {
                    downloadContract.mutate(id)
                  }}
                >
                  {downloadContract.isLoading ? (
                    <Spin
                      style={{
                        display: 'flex',
                        margin: '0 auto',
                      }}
                    />
                  ) : (
                    <span>word-shartnoma</span>
                  )}
                </div>
                {data?.contract?.amount !== data?.contract?.firstPayment && (
                  <div
                    title={"To'lov grafigini ko'rish"}
                    className="contractInfoCountGButton"
                    onClick={() => {
                      setMonthlyPaymentsTableModal(true)
                    }}
                  >
                    <span>To'lov grafigi</span>
                  </div>
                )}
                <div
                  title={"To'lov sahifasiga o'tish"}
                  style={{ marginLeft: '18px' }}
                  className="contractInfoCountGButton"
                  onClick={goToPaymentPlusPageFunc}
                >
                  <span>To'lov sahifasi</span>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="contractInfoContractDate">
                <div style={{ display: 'flex' }}>
                  <span className="oneContractLabel">
                    <span className={'oneContractLabelName'}>
                      Shartnoma sanasi:
                    </span>
                    {data?.contract?.createdDate}
                  </span>
                </div>
                {data?.contract?.type === 'SPECIFIC' && (
                  <>
                    <div style={{ display: 'flex' }}>
                      <span className="oneContractLabel">
                        <span className={'oneContractLabelName'}>
                          Tugash sanasi:
                        </span>
                        {data?.contract?.endDate}
                      </span>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <span className="oneContractLabel">
                        <span className={'oneContractLabelName'}>
                          Boshlanish sanasi:
                        </span>
                        {data?.contract?.startDate}
                      </span>
                    </div>
                  </>
                )}
                <div style={{ display: 'flex' }}>
                  <span className="oneContractLabel">
                    <span className={'oneContractLabelName'}>
                      To'lov muddati:
                    </span>
                    <Tag color="#EF78F1" style={{ fontSize: 16 }}>
                      {data?.contract?.delay}
                    </Tag>
                    oy
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  <span className="oneContractLabel">
                    <span className={'oneContractLabelName'}>
                      Shartnoma summasi:
                    </span>
                    <Tag color="#f50" style={{ fontSize: 16 }}>
                      {data?.contract?.amount?.toLocaleString('ru')}
                    </Tag>
                  </span>
                </div>
                {data?.contract?.type === 'SIMPLE' && (
                  <div style={{ display: 'flex' }}>
                    <span className="oneContractLabel">
                      <span className={'oneContractLabelName'}>
                        Birinchi to'lov:
                      </span>
                      <Tag color="#F3B605" style={{ fontSize: 16 }}>
                        {data?.contract?.firstPayment?.toLocaleString('ru')}
                      </Tag>
                    </span>
                  </div>
                )}
                <div style={{ display: 'flex' }}>
                  <span className="oneContractLabel">
                    <span className={'oneContractLabelName'}>
                      To'langan summa:
                    </span>
                    <Tag color="#108ee9" style={{ fontSize: 16 }}>
                      {data?.contract?.payed?.toLocaleString('ru')}
                    </Tag>
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  <span className="oneContractLabel">
                    <span className={'oneContractLabelName'}>
                      Qolgan summa:
                    </span>
                    <Tag color="#E65C62" style={{ fontSize: 16 }}>
                      {data?.contract?.residue?.toLocaleString('ru')}
                    </Tag>
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  marginTop: '20px',
                  alignItems: 'center',
                }}
              >
                {data?.contract?.firstPayment - data?.contract?.payed > 0 && (
                  <>
                    <div>
                      <p
                        style={{
                          fontFamily: 'IBM Plex Sans',
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: '12px',
                          textAlign: 'right',
                          color: '#FF542F',
                          margin: 0,
                        }}
                      >
                        Boshlang’ich to’lov qoldig’i
                      </p>
                      <Tag
                        color="#FF542F"
                        style={{
                          fontSize: 14,
                          display: 'block',
                          marginLeft: 'auto',
                          textAlign: 'right',
                          margin: '0',
                        }}
                      >
                        {(
                          data?.contract?.firstPayment - data?.contract?.payed
                        ).toLocaleString('ru')}
                      </Tag>
                    </div>
                    <Select
                      allowClear
                      style={{ width: '20%', margin: '0 10px' }}
                      placeholder="To`lov turi"
                      onChange={(e) => {
                        setPayType(e)
                      }}
                    >
                      <Option key={'CASH'} value={'CASH'}>
                        {'Naqd'}
                      </Option>
                      <Option key={'TRANSFER'} value={'TRANSFER'}>
                        {'P2P'}
                      </Option>
                      <Option key={'BANK'} value={'BANK'}>
                        {'Bank'}
                      </Option>
                      <Option key={'CARD'} value={'CARD'}>
                        {'Karta'}
                      </Option>
                    </Select>
                    <div
                      title={"Boshlang'ich to'lov qoldig'ini to'lash"}
                      className="contractInfoPDFButton"
                      style={{ marginTop: '0' }}
                      onClick={() => {
                        if (!paymentCreate.isLoading) payFirstPaymentFunc()
                      }}
                    >
                      {paymentCreate.isLoading ? (
                        <Spin
                          style={{
                            display: 'flex',
                            margin: '0 auto',
                          }}
                        />
                      ) : (
                        <span>To'lov qilish</span>
                      )}
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ width: '97%' }} gutter={[16, 0]}>
            <Col span={12}>
              <Title
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'IBM Plex Sans',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '24px',
                  lineHeight: '100%',
                  color: '#989FB9',
                  marginTop: '30px',
                  marginLeft: '30px',
                  marginBottom: '30px',
                  cursor: 'default',
                }}
              >
                To'lov tarixi
                <span
                  onClick={() => {
                    downloadPayments.mutate(id)
                  }}
                  className="ContractInfoPrinterIconDiv"
                >
                  {downloadPayments.isLoading ? (
                    <Spin className="ContractInfoPrinterIcon" />
                  ) : (
                    <AiFillPrinter className="ContractInfoPrinterIcon" />
                  )}
                </span>
              </Title>
              <CountGraphTable dataForTable={data?.historyPayments} />
            </Col>
            {
              // data?.contract?.type === 'SPECIFIC' ? (
              partsData?.length > 0 ? (
                <Col span={12}>
                  <Title
                    style={{
                      fontFamily: 'IBM Plex Sans',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '24px',
                      lineHeight: '100%',
                      color: '#989FB9',
                      marginTop: '45px',
                      marginBottom: '30px',
                      cursor: 'default',
                    }}
                  >
                    Shartnoma partiyalari &nbsp;
                    <Button
                      type="primary"
                      onClick={() => setPartsModalVisible(true)}
                    >
                      + Mahsulot qo'shish
                    </Button>
                  </Title>
                  <PartsOfContract
                    data={partsData}
                    setModalVisible={setPartsModalVisible}
                    setPartId={setPartId}
                    contractId={id}
                    currency={data?.contract?.contractAmountType}
                  />
                </Col>
              ) : (
                <Col span={12}>
                  <Title
                    style={{
                      fontFamily: 'IBM Plex Sans',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '24px',
                      lineHeight: '100%',
                      color: '#989FB9',
                      marginTop: '45px',
                      marginBottom: '30px',
                      cursor: 'default',
                    }}
                  >
                    Shartnoma mahsulotlari &nbsp;
                    <Button type="primary" onClick={createPart}>
                      + Mahsulot qo'shish
                    </Button>
                  </Title>
                  <ProductsList data={data?.products} />
                </Col>
              )
              // )
              // : (
              //   <Col span={12}>
              //     <Title
              //       style={{
              //         fontFamily: 'IBM Plex Sans',
              //         fontStyle: 'normal',
              //         fontWeight: '500',
              //         fontSize: '24px',
              //         lineHeight: '100%',
              //         color: '#989FB9',
              //         marginTop: '45px',
              //         marginBottom: '30px',
              //         cursor: 'default',
              //       }}
              //     >
              //       Shartnoma mahsulotlari
              //     </Title>
              //     <ProductsList data={data?.products} />
              //   </Col>
              // )
            }
          </Row>
          <Modal
            title="To'lov jadvali"
            visible={monthlyPaymentsTableModal}
            onCancel={() => setMonthlyPaymentsTableModal(false)}
            footer={[
              <Button
                loading={downloadMonthlyPayments.isLoading}
                type="primary"
                onClick={() => {
                  downloadMonthlyPayments.mutate(id)
                }}
              >
                Word
              </Button>,
              <Button
                key="back"
                onClick={() => setMonthlyPaymentsTableModal(false)}
              >
                Ortga
              </Button>,
            ]}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '15px',
              }}
            >
              <TableNextPayment
                loading={isLoading}
                isFetching={isFetching}
                data={data?.tableMonthlyPayments}
              />
            </div>
          </Modal>
          <PartsOfContractModal
            visible={partsModalVisible}
            setVisible={setPartsModalVisible}
            id={partId}
            setId={setPartId}
            currency={data?.contract.contractAmountType}
          />
          <SponsorsModal
            visible={sponsorsModalVisible}
            setVisible={setSponsorsModalVisible}
            sponsors={data?.contract?.sponsorDtoList || []}
          />
          {/*<Modal*/}
          {/*  title="To'lov jadvali arxivi"*/}
          {/*  visible={historyModalOpen}*/}
          {/*  onCancel={() => setHistoryModalOpen (false)}*/}
          {/*  width={"40%"}*/}
          {/*  footer={[*/}
          {/*    <Button key="back" onClick={() => setHistoryModalOpen (false)}>*/}
          {/*      Ortga*/}
          {/*    </Button>,*/}
          {/*  ]}*/}
          {/*>*/}
          {/*  <HistoryTableMonthlyPayment id={id}/>*/}
          {/*</Modal>*/}
          {/*<Modal*/}
          {/*  title="Shartnomani bekor qilish"*/}
          {/*  visible={cancelModal}*/}
          {/*  onCancel={() => setCancelModal (false)}*/}
          {/*  footer={false}*/}
          {/*>*/}
          {/*  <div style={{display: 'flex', justifyContent: "space-around", marginTop: '15px'}}>*/}
          {/*    <Button onClick={() => setCancelModal (false)}>Ortga</Button>*/}
          {/*    <Button type="primary" onClick={() => {*/}
          {/*      setCancelModal (false)*/}
          {/*      setTransferModal (true)*/}
          {/*    }}>Boshqa mijozga o'tqazish</Button>*/}
          {/*    <Button danger onClick={cancelContractFunc}>Tasdiqlash</Button>*/}
          {/*  </div>*/}
          {/*</Modal>*/}
          {/*<Modal*/}
          {/*  title="Boshqa mijoz nomiga o'tkazish"*/}
          {/*  visible={transferModal}*/}
          {/*  onCancel={() => setTransferModal (false)}*/}
          {/*  width={700}*/}
          {/*  footer={[*/}
          {/*    <Button onClick={() => {*/}
          {/*      setCancelModal (true)*/}
          {/*      setTransferModal (false)*/}
          {/*    }}>*/}
          {/*      Ortga*/}
          {/*    </Button>,*/}
          {/*    <Button type="primary" disabled={!selectedClient.selected} onClick={() => {*/}
          {/*      changeContractFunc ()*/}
          {/*      setTransferModal (false)*/}
          {/*    }}>*/}
          {/*      Tasdiqlash*/}
          {/*    </Button>,*/}
          {/*  ]}*/}
          {/*>*/}
          {/*  <Row gutter={[16, 16]}>*/}
          {/*    <Col span={12} style={{maxHeight: '200px', overflow: 'auto'}}>*/}
          {/*      <Input*/}
          {/*        placeholder="Mijozni qidirish.."*/}
          {/*        onChange={(e) => {*/}
          {/*          setsearchClientTransferCont (e.target.value)*/}
          {/*        }}*/}
          {/*        onPressEnter={searchClientFunc}/>*/}
          {/*      {spin ? <Spin/> :*/}
          {/*        searchPersonsData.length === 0 ?*/}
          {/*          <Link*/}
          {/*            style={{*/}
          {/*              marginTop: '15px'*/}
          {/*            }}*/}
          {/*            to={"/main/clientPlus"}>*/}
          {/*            Mijoz qo'shish*/}
          {/*          </Link> :*/}
          {/*          searchPersonsData.map ((client, index) => (*/}
          {/*            <div*/}
          {/*              className='YuridikCard'*/}
          {/*              style={*/}
          {/*                index === selectedClient.index && selectedClient.selected ?*/}
          {/*                  {*/}
          {/*                    backgroundColor: '#FFE500',*/}
          {/*                    width: '95%',*/}
          {/*                    height: '65px',*/}
          {/*                  } : {*/}
          {/*                    width: '95%',*/}
          {/*                    height: '65px',*/}
          {/*                  }*/}
          {/*              }*/}
          {/*              onClick={() => {*/}
          {/*                if (index !== selectedClient.index) {*/}
          {/*                  setSelectedClient ({*/}
          {/*                    index: index,*/}
          {/*                    selected: true,*/}
          {/*                    data: client*/}
          {/*                  })*/}
          {/*                } else {*/}
          {/*                  setSelectedClient ({*/}
          {/*                    index: index,*/}
          {/*                    selected: !selectedClient.selected,*/}
          {/*                    data: client*/}
          {/*                  })*/}
          {/*                }*/}
          {/*              }}>*/}
          {/*              <div*/}
          {/*                style={{*/}
          {/*                  fontFamily: 'IBM Plex Sans',*/}
          {/*                  fontStyle: 'normal',*/}
          {/*                  fontWeight: '500',*/}
          {/*                  fontSize: '16px',*/}
          {/*                  lineHeight: '100%',*/}
          {/*                  paddingBottom: '10px',*/}
          {/*                }}>*/}
          {/*                {*/}
          {/*                  client.name ? client.name : client.lastname + " " + client.firstname + " " + client.fathersName*/}
          {/*                }*/}
          {/*              </div>*/}
          {/*              <div*/}
          {/*                style={{*/}
          {/*                  fontFamily: 'IBM Plex Sans',*/}
          {/*                  fontStyle: 'normal',*/}
          {/*                  fontWeight: '500',*/}
          {/*                  fontSize: '16px',*/}
          {/*                  lineHeight: '100%',*/}
          {/*                }}>*/}
          {/*                {*/}
          {/*                  // eslint-disable-next-line array-callback-return*/}
          {/*                  client.clientPhones && client.clientPhones.map (phn => {*/}
          {/*                    if (phn.active) {*/}
          {/*                      return (*/}
          {/*                        <>{phn.phone}</>*/}
          {/*                      )*/}
          {/*                    }*/}
          {/*                  })*/}
          {/*                }*/}
          {/*              </div>*/}
          {/*            </div>*/}
          {/*          ))*/}
          {/*      }*/}
          {/*    </Col>*/}
          {/*    <Col span={12}>*/}
          {/*      <Typography className="clientInfoInsideModal">*/}
          {/*        Mijoz:*/}
          {/*        <span style={{color: '#939397', paddingLeft: '6px'}}>*/}
          {/*                      {selectedClient.selected &&*/}
          {/*                      (selectedClient.data.name ? selectedClient.data.name : selectedClient.data.lastname + " " + selectedClient.data.firstname + " " + selectedClient.data.fathersName)}*/}
          {/*                  </span>*/}
          {/*      </Typography>*/}
          {/*      <Typography className="clientInfoInsideModal">*/}
          {/*        Tel:*/}
          {/*        {selectedClient.selected &&*/}
          {/*        selectedClient.data.clientPhones && selectedClient.data.clientPhones.map (phn => (*/}
          {/*          <span style={{color: '#939397', paddingLeft: '36px', display: 'block'}}>*/}
          {/*                          {phn.phone}*/}
          {/*            {phn.active &&*/}
          {/*            <BiMessageCheck style={{*/}
          {/*              color: 'green',*/}
          {/*              paddingLeft: '5px',*/}
          {/*              fontSize: '18px'*/}
          {/*            }}/>}*/}
          {/*                      </span>*/}
          {/*        ))}*/}
          {/*      </Typography>*/}
          {/*      <Typography className="clientInfoInsideModal">*/}
          {/*        {selectedClient.selected ?*/}
          {/*          (selectedClient.data.name ? "Inn" : "Passport")*/}
          {/*          : "Ikkilamchi ma'lumot:"}*/}
          {/*        <span style={{color: '#939397', paddingLeft: '6px'}}>*/}
          {/*                      {selectedClient.selected &&*/}
          {/*                      (selectedClient.data.name ? selectedClient.data.inn : (selectedClient.data.passportSerial + " " + selectedClient.data.passportNumber))}*/}
          {/*                  </span>*/}
          {/*      </Typography>*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</Modal>*/}
        </div>
      )}
    </>
  )
}

export default ContractIdInfo
