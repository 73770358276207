import React from "react";
import {Typography} from "antd";
import {BiBuildings, BiLogOutCircle, BsPeople} from "react-icons/all";
import {Link, useLocation} from "react-router-dom";
import AuthService from "../../services/AuthService";


const Sidebar = () => {
  const location = useLocation()

  const isActive = (checkValue) => {
    if (checkValue === location.pathname) {
      return " active"
    } else {
      return ""
    }
  }

  return (
    <div>
      <div style={{
        width: '100%',
        height: '100vh',
        backgroundColor: '#fff',
      }}>
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: 'center',
          paddingTop: '2vh'
        }}>
          <img className="sidebarTopLogoDev" src={process.env.PUBLIC_URL + "/Logo.png"} alt={"Uysot-logo"}/>
          <Typography
            style={{
              fontFamily: 'IBM Plex Sans',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '1.5vw',
              marginLeft: '1vw',
              paddingTop: '1.5vh',
              textAlign: 'center',
              lineHeight: '100%'
            }}>
            Uysot
          </Typography>
        </div>
        <Link
          style={{
            marginTop: '3vh'
          }}
          to={'/developer/company'}
          className={`dashboardMenuItem${isActive('/developer/company')}`}>
          <div
            className={`dashboardMenuItemIcon${isActive("/developer/company")}`}>
            <BiBuildings style={{
              fontSize: '1.5vw',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}/>
          </div>
          <Typography
            className={`dashboardMenuItemText${isActive("/developer/company")}`}>
            Kompaniyalar
          </Typography>
        </Link>
        <Link
          to={'/developer/companyEmployees'}
          className={`dashboardMenuItem${isActive('/developer/companyEmployees')}`}>
          <div
            className={`dashboardMenuItemIcon${isActive("/developer/companyEmployees")}`}>
            <BsPeople style={{
              fontSize: '1.5vw',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}/>
          </div>
          <Typography
            className={`dashboardMenuItemText${isActive("/developer/companyEmployees")}`}>
            Xodimlar
          </Typography>
        </Link>
        <Link
          onClick={() => {
            AuthService.logout()
          }}
          to={'/'}
          className={`dashboardMenuItem logout`}>
          <div
            className={`dashboardMenuItemIcon`}>
            <BiLogOutCircle style={{
              fontSize: '1.5vw',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}/>
          </div>
          <Typography
            className={`dashboardMenuItemText`}>
            Chiqish
          </Typography>
        </Link>
      </div>
    </div>
  )
}

export default Sidebar;
